:root{
	--rad:5px;
	--pad:5px;
	--mar:1rem;
	--md-wid:660px;
	--sm-wid:400px;
	--dk-gry:#444;
	--md-gry:#666;
	--lt-gry:#aaa;
	--ltr-gry:#eee;
	--nav-h:3rem;
}
html{
	font-size: 16px;
	height:100vh;
	margin:0;
	left:0;
}
body{
	/*height:100vh;*/
	margin:0 auto;
	max-width:1150px;
}
a,a:link,a:visited,a:active{
	text-decoration:none;
	color:inherit;
	text-align:inherit;
	display:inherit
}
.container{
	display: grid;
	height:100vh;
	width:100%;
	grid-template-columns: 1fr;
	grid-template-rows: auto 1fr auto;
	grid-template-areas:
		"nav"
		"main"
		"footer";
	grid-gap: 3rem;
}
nav{
	grid-area: nav;
	display: grid;
	height:auto;
	grid-template-columns: repeat(2, 1fr) repeat(2,.8fr) repeat(2, 1fr);
	grid-template-rows: 1fr 1fr;
	grid-template-areas:
		"nav1 nav1 navl navl nav2 nav2"
		"nav3 nav3 navl navl nav4 nav4";
	margin-top:0.5rem;
}
.nav-item,.nav-logo{
	text-align: center;
	border-radius:0.5rem;
	margin:1px;
	/*padding:var(--pad);*/
}
.nav-logo{
	grid-area:navl;
}
#eng{
	grid-area:nav1;
}
#data{
	grid-area:nav2;
}
#des{
	grid-area:nav3;
}
#art{
	grid-area:nav4;
}
h6.nav-item{
	cursor:pointer;
}
#logo{
	max-height:7rem;
	/*margin:0.2rem 0 0 0;*/
	border-radius:50%;
	border:2px solid #ddd;
	cursor:pointer;
	vertical-align:middle;
}
#main-img,.content-img{
	display:block;
	width:100%;
	border-radius:50%;
	vertical-align:middle;
	margin:0 auto;
}
.content-img{border-radius:5px;}
.per50,.per40,.per60,.per100{
	display:block;
	/*text-align:center;*/
	margin:0 auto;
	padding:0 2%;
	margin-top:1rem;
	margin-bottom:1rem;
}
.span2,.span2img{
	grid-column: span 2;
	width:100%;
}
.span2img{
	width:auto;
	max-width:50%;
}

@media(hover: hover) and (pointer: fine) {
	h6.nav-item:hover, footer .link:hover{
		color:#0af;
	}
	#logo:hover{
		border:2px solid #0af;
	}
}
main{
	grid-area: main;
	display: grid;
	height: auto;
	grid-template-columns: 1fr 1fr;
	grid-auto-rows: auto;
	justify-items: center;
	align-items: center;
	grid-gap: 2rem 2rem;
	width:96%;
	margin:0 auto;
}
footer{
	grid-area: footer;
	display: flex;
	justify-content: space-between;
	width:85%;
	margin:0 auto;
	margin-bottom:0.5rem;
}
.sm-line{
	content:"";
	height:2px;
	width:0.5rem;
	border-bottom:2px solid var(--lt-gry);
	margin:0 auto;
}
p, h1, h2, h3, h4, h5, h6, a{
	font-family:sans-serif;
	margin:0;/*.25em;*/
	font-weight: 300;
}
h1{
	font-size:2rem;
	line-height:2.5em;
	text-align:center;
	color:var(--md-gry);
}
h2{
	font-size:1.6rem;
	line-height:2em;
	/*font-weight:400;*/
	/*text-align:center;*/
	color:var(--dk-gry);
	/*text-decoration:underline;*/
}
h3{
	font-size:2rem;
	line-height:2em;
	text-align:center;
	color:var(--dk-gry);
}
h4{
	font-size:1.6rem;
	color:var(--dk-gry);
	/*line-height:2em;*/
}
h6{
	font-size:1.2rem;
	line-height:3em;
	color:var(--md-gry);
	display:inline-block;
}
h5{
	font-size:1.2rem;
	line-height:1.5em;
	color:var(--md-gry);
}
p{
	font-size:1.1rem;
	color:var(--dk-gry);
	line-height:1.5em;
}
.txt,.txt-c{
	margin-top:0.5em;
	margin-bottom:0.5em;
	float:none;
	width:100%;
}
.txt-c{
	color:var(--lt-gry);
	text-align:center;
	/*text-decoration:underline;*/
	font-size:1.4rem;
}
.section-e > .txt{
	margin-bottom:1.5em;
}
.flex-title{
	display:flex;
	flex-direction:row;
	height:inherit;
}
.label{
	border-radius:5px;
	border:2px solid #ddd;
	width:max-content;
	height:max-content;
	padding: 0 0.25em;
	align-self:right;
	margin:.75em 0 .75em 0.25em;
	line-height:1.5em;
	font-size:1em;
}
.listitem{
	border-left:2px solid var(--lt-gry);
	margin-left:1%;
	margin-bottom:1rem;
	width:90%;
	padding-left:1%;
}

footer p{
	color:var(--lt-gry);
}

/* --- MARKUP SECTIONS --- */

.section{
	display:flex;
	/*width:100%;*/
	margin-right:1em;
	flex-direction:row;
	justify-content:space-between;
	cursor:pointer;
	transition: padding .2s;
	-webkit-transition: padding .2s;
}
.section-e,.section-c{
	overflow:hidden;
	width:100%;
	margin-bottom:1em;
	/*transition: transform 0.5s ease-out 0s;*/
	/*transition: max-height .5s linear 0s;*/
}
.section-e{max-height:auto}.section-c{max-height:0;}
/*.section-e{transform:scaleY(1);}.section-c{transform:scaleY(0);}*/
.arrow-e,.arrow-c{
	cursor:pointer;
	/*align-self:flex-end;*/
	/*margin-left:auto;*/
	/*width:min-content;*/
	transition: transform 0.3s linear 0s;
	-webkit-transition: transform 0.3s linear 0s;
	/*color:#0af;*/
	color:var(--lt-gry);
}
.arrow-e{
	transform: rotate(90deg);
	-webkit-transform: rotate(90deg);
}
.comment{
	margin:0.5em 2em;
	padding:0.2em 1em;
	background-color:#eee;
	border-radius:5px;
}

.svg-img{
	max-height:500px;
	margin:0 auto;
	display:block;
	max-width:95%;
}
.caption{
	text-decoration:italic;
	color:var(--lt-gry);
	text-align:center;
}

/* --- */

@media screen and (max-width:550px){
	html{
		font-size:15px;
	}
	footer p{
		color:var(--lt-gry);
		font-size:13px;
	}
	/*h6{
		line-height:1.3em;
		}*/
	/*
	.container{
		grid-template-columns: 1fr;
		grid-template-rows: var(--nav-h) 2fr 1.2fr 1.2fr 1.2fr 0.4fr;
		grid-template-areas:
			"nav"
			"main"
			"content1"
			"content2"
			"content3"
			"footer";
	}
	*/
}

/* --- MAZE --- */
#maze {
	display: grid;
	height: auto;
	/*grid-template-columns: repeat(5, 1fr);*/
	grid-auto-rows: auto;
	justify-items: center;
	align-items: center;
	grid-gap: 1px;
	width: fit-content;
	margin:0 auto;
	transition: border-color .2s;
	-webkit-transition: border-color .2s;
}
#maze > div{
	/*border:2px solid var(--lt-gry);*/
	max-width:100px;
	min-width:19px;
	max-height:100px;
	min-height:19px;
	/*height:100px;*/
	/*aspect-ratio: 1 / 1;*/
	box-sizing:border-box;
}

@media (hover:hover){
	.button:hover,.button > a:hover{
		color:#0af;
	}
	#maze > div:hover{
		cursor:pointer;
		background-image: radial-gradient(#fff 20%,#eee 20.001%,#eee 35%,#fff 35.001%);

	}
	.section:hover{
		padding-right: 2em;
		padding-left: 1em;
	}
}

.c0000,.c0001,.c0010,.c0100,.c0011,.c0101,.c0110,.c0111{
	border-top:3px solid #fafafa;
}
.c0000,.c0001,.c0010,.c1000,.c0011,.c1001,.c1010,.c1011{
	border-right:3px solid #fafafa;
}
.c0000,.c0001,.c1000,.c0100,.c1001,.c0101,.c1100,.c1101{
	border-bottom:3px solid #fafafa;
}
.c0000,.c1000,.c0010,.c0100,.c1010,.c1100,.c0110,.c1110{
	border-left:3px solid #fafafa;
}
.c1000,.c1100,.c1010,.c1001,.c1110,.c1101,.c1011,.c1111{
	border-top:3px solid var(--md-gry);
}
.c0100,.c0110,.c1100,.c0101,.c1110,.c1101,.c0111,.c1111{
	border-right:3px solid var(--md-gry);
}
.c0010,.c1010,.c0110,.c0011,.c1110,.c0111,.c1011,.c1111{
	border-bottom:3px solid var(--md-gry);
}
.c0001,.c0101,.c0011,.c1001,.c0111,.c1101,.c1011,.c1111{
	border-left:3px solid var(--md-gry);
}
.c0000,.c0001,.c0010,.c0100,.c1000,.c0011,.c0101,.c0110,.c1001,.c1010,.c1100,.c0111,.c1101,.c1011,.c1110,.c1111{
	transition: border-color .15s;
	-webkit-transition: border-color .15s;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
#buttons{
	display:flex;
	flex-direction:row;
	justify-content:center;
}
.button,.complete,.incomplete{
	text-align:center;
	margin:0 1.8em;
	/*margin:0 auto;*/
	margin-bottom:2em;
}
.button > h6,.button > a,.complete > h6,.incomplete > h6{
	text-align:center;
	padding:0 2rem;
	border:2px solid #0af;
	border-radius:10px;
	cursor:pointer;
}
.complete > h6{
	cursor:default;
	border:2px solid #cfc;
	color:#aaa;
}
.incomplete > h6{
	cursor:default;
	border:2px solid #fcc;
	color:#aaa;
}
@media (hover:hover){
	.button > h6:hover{
		border-radius:3rem;
	}
	.section:hover > h2{
		/*color:var(--lt-gry);*/
		color:#0af;
	}
}
.path{
	background-image: radial-gradient(#abf 30%,#fff 30.001%);
}
.start{
	background-image: radial-gradient(#8f8 40%,#fff 40.001%); /* 0f0 */
}
.finish{
	background-image: radial-gradient(#f88 40%,#fff 40.001%); /* f00 */
}
/*#buttons > div{
	width:50%;
	float:left;
}*/
#inputs{
	display: grid;
	height: auto;
	grid-template-columns: repeat(3, 1fr);
	grid-auto-rows: auto;
	justify-items: center;
	align-items: center;
	grid-gap: 1rem;
	width: 100%;
	margin: 0 auto;
	margin-bottom: 3rem;
}
#inputs > *{
	border:none;
	text-align: center;
	font-size: 1.1em;
	line-height:1.5em;
}
#inputs > input{
	border-bottom:1px solid var(--lt-gry);
	text-align: center;
	padding: 1rem 0;
	color:#0af;
}
#inputs > div{
	width:80%;
	border-bottom:1px solid var(--lt-gry);
}
.inputsInner{
	float:left;
	width:50%;
	text-align:center;
	color:var(--dk-gry);
}
.arrows{
	float:left;
	width:25%;
	cursor:pointer;
	color:#0af;
}

.i1{order:1;}.i2{order:4;}.i3{order:2;}.i4{order:5;}.i5{order:3;}.i6{order:6;}
@media (max-width: 750px){
	#inputs{
		grid-template-columns: repeat(2, 1fr);
		grid-gap:1rem 0;
	}
	.i1{order:2;}.i2{order:1;}.i3{order:4;}.i4{order:3;}.i5{order:6;}.i6{order:5;}
	#inputs > input{
		border: none;
		text-align:left;
		padding:0;
		margin-left:10%;
		width:90%;
	}
	#inputs > p{
		text-align:right;
		width:80%;
		margin-right:20%;
	}
	#inputs > div{
		border:none;
	}
}

/* Diabetes */
.diabetes-container {
	height:100vh;
	display: grid;
	grid-template-rows: 1fr min-content 1fr;
	grid-template-columns: 1fr min-content 1fr;
}

/*.diabetes,.signin {
	width:300px;
	margin: 0 auto;
	height: 50vh;
	margin-top: 25vh;
}*/

.diabetes,.signin {
	width: 300px;/*
	margin: 0 auto;*/
	grid-row: 2;
	grid-column: 2;
}

.diabetes > input, .signin > input {
	box-sizing: border-box;
	width: 100%;
	line-height: 2rem;
	padding:0.5rem 0;
	border-radius: 10px;
	margin: 1rem auto;
	text-align: center;
	float: none;
	border: var(--lt-gry) solid 2px;
	/*background-color:var(--ltr-gry);*/
	font-size: 1.5rem;
}

.diabetes input.result {
	border: none;
	background: var(--ltr-gry);
	width: 150px;
	margin-left: 75px;
}

.signin button {
	width: 50%;
	padding: 0.5rem 0;
	margin: 1rem 25%;
	border-radius: 10px;
	border: none;
	cursor: pointer;
	background-color:var(--ltr-gry);
	line-height: 2rem;
	font-size: 1.5rem;
}

.diabetes > h4 {
	text-align: center;
	line-height: 2rem;
}

.diabetes > .spacer {
	height: 2rem;
}

.diabetes .button-row {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	gap: 0.3rem;
}

.diabetes button {
	border: none;
	font-size: 1.5rem;
	line-height: 2rem;
	border-radius: 5px;
	padding: 0;
	margin: 0;
	cursor: pointer;
}
.diabetes button.selected {
	background-color: var(--dk-gry);
	color: #fff;
}